import { accessTypes } from "./accessTypes";

export const roles = {
  owner: [
    ...Object.values(accessTypes.eventManagement),
    ...Object.values(accessTypes.contractManagement),
    ...Object.values(accessTypes.statistics),
  ],

  admin: [
    ...Object.values(accessTypes.eventManagement),
    ...Object.values(accessTypes.contractManagement),
    ...Object.values(accessTypes.statistics),
  ],

  "event director lead auditor": [...Object.values(accessTypes.statistics)],

  "event director lead": [...Object.values(accessTypes.statistics)],

  "event director": [...Object.values(accessTypes.statistics)],

  "event director assistant": [...Object.values(accessTypes.statistics)],

  staffer: [...Object.values(accessTypes.statistics)],

  "staffing manager": [...Object.values(accessTypes.statistics)],

  associate: [...Object.values(accessTypes.statistics)],
};
