import { get, patch, post } from "./ServicesApi";

const createSchool = (body) => {
  const schoolPromise = post("/v1/school", body);
  return schoolPromise;
};

const getSchools = (params) => {
  const schoolPromise = get("/v1/school", { params });
  return schoolPromise;
};

const updateMultiSchools = (body) => {
  const clientPromise = patch(`/v1/school/multi-update/`, body);
  return clientPromise;
};

export { getSchools, createSchool, updateMultiSchools };
