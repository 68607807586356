import { debounce, reject } from "lodash";
import React, {
  useEffect,
  useState,
  createContext,
  useRef,
  useMemo,
} from "react";
import { io } from "socket.io-client";
import { convertStringToNumber, extractIds, generateToast } from "../helper";
import { INITIAL_STATE } from "../constant/Editor";
import { useIdb } from "../utils/IndexedDB";
import { CalculateCosts, getTPEResp } from "../helper/CostHelpers";
import { ERROR, SUCCESS, TOP_RIGHT } from "../constant";
import { createClient, createClientType } from "../services/ClientService";
import { createRegion } from "../services/RegionService";
import { createEventType, getEventTypes } from "../services/EventService";
import { createSchool } from "../services/SchoolService";
import {
  getContracts,
  getContractsTemplate,
  getParentContractTitles,
  updateContract,
  updateContractsTemplate,
} from "../services/ContractServices";
import { getRegions } from "../services/RegionService";
import { getClientTypes } from "../services/ClientTypeServices";
import { getClients } from "../services/ClientService";
import { getSchools } from "../services/SchoolService";
import { getAllEmployee, getSalesEmployee } from "../services/EmployeeService";
import { getStaffDetails } from "../services/StaffServices";
import {
  getTransportationDetails,
  getTransportationType,
} from "../services/TransportationServices";
import { getProductionDetails } from "../services/ProductionServcies";
import { getVenueData } from "../services/VenueServices";
import { addSocketIdInterceptor, instance } from "../services/ServicesApi";
import { useDispatch } from "react-redux";
import { getEventOptionsThunk } from "../redux/reducers/EventReducer";
export const socket = io(process.env.REACT_APP_API_HOST.replace("/api", ""));

export const AppContext = createContext();

const loadingType = {
  type: "",
  isLoading: false,
};

const AppProvider = ({ children }) => {
  let initialState = {};
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState();
  const [activeUsers, setActiveUsers] = useState([]);
  const [templateText, setTemplateText] = useState();
  const [templateVersion, setTemplateVersion] = useState();
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [activeContracts, setActiveContracts] = useState([]);
  const summaryRef = useRef();
  const freezeHandle = useRef(false);
  const mapIndexRef = useRef(0);
  const formTabIndex = useRef(0);
  const activeUsersRef = useRef([]);
  const [summary, setSummary] = useState([{}]);
  const [contractTab, setContractTabs] = useState([]);
  const [mapIndex, setMapIndex] = useState(undefined);
  const [editIndex, setSetIndex] = useState(undefined);
  const [user, setUser] = useState();
  const [salesEmp, setSalesEmp] = useState([]);
  const [isTypeLoading, setTypeLoading] = useState(loadingType);
  const [contracts, setContracts] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [parentOptions, setParentOptions] = useState([]);

  const { role } = user || {};
  const isStaffer = ["Staffer"].includes(role);
  const rolesToAccess = ["Owner"];
  const hasUpdatePower = rolesToAccess.includes(role);

  const parentTitleWithOptions = useMemo(() => {
    return parentOptions?.map(({ id, title }) => ({
      id,
      name: title,
    }));
  }, [parentOptions]);

  const getSummary = (index = 0) => {
    return summary[index];
  };

  const [notifications, setNotifications] = useState([]);
  const [transportationPriceList, setTransportationPriceList] = useState([]);
  const { totalESCost, totalPCost, totalTransCost, totalAccountCost } =
    CalculateCosts(getSummary(mapIndex));
  const contractTotal = totalESCost + totalTransCost + totalPCost;
  const setTabRef = useRef();
  const tabIndex = useRef();
  const initialFetched = useRef();
  const isApiLoading = useRef(false);
  const [editorDBState, setEditorDB, isStateLoading] = useIdb(
    "editor-state",
    INITIAL_STATE
  );
  const isAdmin = [
    "admin",
    "event director lead auditor",
    "event director lead",
    "owner",
  ].includes(role?.toLowerCase());

  const fetchDetails = async () => {
    setTypeLoading({
      type: "Contracts",
      isLoading: true,
    });

    getContracts({ shouldLoadEvents: true, shouldLoadClients: true })
      .then((contract) => {
        setContracts(contract.data);
      })
      .finally(() => setTypeLoading(loadingType));
  };

  const fetchParentOptions = async () => {
    getParentContractTitles().then(({ data }) => {
      setParentOptions(data);
    });
  };

  const handleContractUpdate = useMemo(() => {
    return debounce(async (id, obj) => {
      try {
        await updateContract(
          id,
          extractIds(obj, {
            addClientId: true,
          })
        );
      } catch (err) {
        console.log({ err });
        generateToast(
          "Error in saving updates, Please refresh site & try again!",
          ERROR,
          TOP_RIGHT
        );
      }
    }, 500);
  }, []);

  const updatePDFContract = async (id, pdfContract) => {
    handleContractUpdate(id, { pdfContract });
  };

  useEffect(() => {
    user && fetchDetails();

    // eslint-disable-next-line
  }, [user]);

  const handleUpdate = (data) => {
    const { event_sender, contractData } = data;
    if (event_sender !== socket.id) {
      let summaryArray = [...summaryRef.current];

      const indexOf = summaryArray.findIndex(({ id }) => {
        return id === contractData.id;
      });
      if (indexOf >= 1) {
        freezeHandle.current = true;
        summaryArray[indexOf] = { ...summaryArray[indexOf], ...contractData };
        summaryRef.current = summaryArray;
        setSummary(summaryArray);
      }
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("user_update", (data) => {
        const details = Object.keys(data).map((key) => {
          const indexData = data[key]?.userDetails;
          if (key !== "summary") {
            return { ...indexData, active_input: data[key]?.active_input };
          } else {
            return data[key]; // Just nest the whole data index
          }
        });
        setActiveUsers([...details]);
        activeUsersRef.current = [...details];
      });

      socket.on("channel_update", (data) => {
        handleUpdate(data);
      });

      socket.on("contract_update", (data) => {
        setActiveContracts(data);
      });
    }
    // eslint-disable-next-line
  }, [socket]);

  const handleContractDetails = (data, index) => {
    const inputArray = [...contractTab];
    inputArray[index - 1] = data;
    setContractTabs(inputArray);
  };

  const handleSummary = async (obj, index = 0) => {
    if (!index) return {};
    handleContractDetails(obj, index);
    const data = [...summary];
    data[index] = obj;
    setSummary(data);
    summaryRef.current = data;
    localStorage.setItem("data", JSON.stringify(data));
  };

  const closeFun = (index, e, isCreate, delId) => {
    e?.stopPropagation();
    const inputArray = [...contractTab];
    let summaryArray = [...summary];
    if (isCreate) {
      summaryArray[0] = initialState;
    } else {
      summaryArray = reject(summaryArray, function (el) {
        return el?.id === delId;
      });
    }
    inputArray.splice(index, 1);
    setSummary(summaryArray);
    summaryRef.current = summaryArray;
    setContractTabs(inputArray);
    if (tabIndex?.current === contractTab.length + 1)
      setTabRef.current(tabIndex?.current - 1);
  };

  const handleReset = () => {
    setContractTabs([]);
    setSummary([]);
    summaryRef.current = [];
    setUser();
    setIsAuthenticated(0);
  };

  const getTransQuant = () => {
    const summary = getSummary(mapIndex);
    const { transportationBillings } = summary;
    const transQuant = transportationBillings.map((data) => data?.quantity);

    const totalTransQuant = transQuant?.reduce(
      (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
      0
    );
    return totalTransQuant;
  };

  const fetchContractTemplateToState = async () => {
    setIsTemplateLoading(true);

    try {
      const res = await getContractsTemplate();
      let templateHtml = INITIAL_STATE;

      if (!res.data) {
        const createRes = await updateContractsTemplate({ html: templateHtml });
        if (createRes.success) {
          const res = await getContractsTemplate();
          templateHtml = res?.data?.html;
        }
      } else {
        templateHtml = res?.data?.html;
      }

      setTemplateVersion(res?.data?.versionId);
      setTemplateText(templateHtml);
    } catch (err) {
      console.log("Error:", err);
    } finally {
      setIsTemplateLoading(false);
    }
  };

  const getUpFrontCost = (propsSummary) => {
    const summary = propsSummary || getSummary(mapIndex);
    const {
      expenses,
      finalBarSales,
      barGuarantee,
      clientResponsibility,
      shouldConsiderClientResponsibility,
    } = summary || {};

    const processExpenses = () => {
      const beverage = expenses
        ?.filter((obj) => obj.expenseType?.name === "Beverage")
        .sort((a, b) => a.id - b.id);

      const food = expenses
        ?.filter((obj) => obj.expenseType?.name === "Food")
        .sort((a, b) => a.id - b.id);

      const rental = expenses
        ?.filter((obj) => obj.expenseType?.name === "Misc")
        .sort((a, b) => a.id - b.id);

      return { beverage, food, rental };
    };

    const { beverage, rental, food } = processExpenses();

    const sumRentalCost = rental?.map(({ cost }) => cost);
    const sumFoodCost = food?.map(({ cost }) => cost);
    const sumBevCost = beverage?.map(({ cost }) => cost);

    const rental_cost = sumRentalCost?.reduce(
      (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
      0
    );

    const food_cost = sumFoodCost?.reduce(
      (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
      0
    );

    const bev_cost = sumBevCost?.reduce(
      (a, b) => convertStringToNumber(a || 0) + convertStringToNumber(b || 0),
      0
    );

    function TotalBevCost() {
      const isGambleSucceed =
        convertStringToNumber(finalBarSales) >
        convertStringToNumber(barGuarantee);
      if (!convertStringToNumber(finalBarSales)) {
        return getTPEResp(summary) + bev_cost;
      } else if (isGambleSucceed) {
        return 0 + bev_cost;
      }
      const clientRespDiff =
        clientResponsibility - finalBarSales >= 0
          ? clientResponsibility - finalBarSales
          : 0;

      const clientDiff = shouldConsiderClientResponsibility
        ? clientRespDiff
        : 0;
      return (
        convertStringToNumber(bev_cost) +
        convertStringToNumber(barGuarantee - finalBarSales - clientDiff)
      );
    }

    const bevTotalCost = TotalBevCost();

    return rental_cost + food_cost + bevTotalCost || 0;
  };

  const [getClientName, setGetClientName] = useState([]);
  const [barSalesClients, setBarSalesClients] = useState([]);
  const [getRegion, setGetRegion] = useState([]);
  const [getClientType, setGetClientType] = useState([]);
  const [getSchoolName, setGetSchoolName] = useState([]);
  const [getEventType, setGetEventType] = useState([]);
  const [getEmployees, setEmployees] = useState([]);
  const [getProductionPriceList, setProductionPriceList] = useState([]);
  const [getTransPriceList, setTransPriceList] = useState([]);
  const [getTransType, setTransType] = useState([]);
  const [getVenue, setGetVenue] = useState([]);
  const [getESPriceList, setESPriceList] = useState([]);

  // Memoize the mapped salesEmp data to prevent continuous remapping
  const salesEmpWithOptions = useMemo(() => {
    return salesEmp?.map((emp) => ({
      id: emp?.id,
      name: `${emp?.firstName} ${emp?.lastName}`, // Combine first name and last name
    }));
  }, [salesEmp]);

  const fetchClient = () => {
    getClients().then((getClientName) => setGetClientName(getClientName.data));
  };

  const fetchBarSalesClients = async () => {
    return getClients({ havingBarSales: true }).then((getClientName) =>
      setBarSalesClients(getClientName.data)
    );
  };

  const onGetESPricing = async () => {
    getStaffDetails().then((res) => setESPriceList(res.data));
  };

  const getSalesEmployees = () => {
    getSalesEmployee().then((res) => {
      setSalesEmp(res);
    });
  };

  const getAllEmployees = () => {
    getAllEmployee().then((res) => {
      setAllEmployees(res);
    });
  };

  const initializeEditor = async (mockState) => {
    const editorData = mockState;
    const contentState = editorData;
    setEditorState(contentState);
  };

  useEffect(() => {
    initializeEditor();
    // eslint-disable-next-line
  }, [isStateLoading]);

  const getNotifications = () => {
    fetch(`/v1/user/notifications`, {
      method: "GET",
      headers: { jwt_token: localStorage.token },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.msg) return;
        return setNotifications(data);
      });
  };
  // When the user moves their pointer, update their presence
  // eslint-disable-next-line
  const handlePointMove = React.useCallback(
    debounce(
      (e) => {
        const summary = summaryRef.current;

        const filterContractActiveUser = activeUsersRef?.current?.filter(
          ({ active_input }) =>
            active_input?.id === summary[mapIndexRef.current]?.id
        );
        const allowedUpdate =
          filterContractActiveUser?.length > 1 ||
          filterContractActiveUser?.[0]?.username !== user.username;

        if (mapIndexRef.current && allowedUpdate) {
          const active_input = {
            id: summary[mapIndexRef.current]?.id,
            point: [e.x, e.y],
            user: user.username,
            tabIndex: formTabIndex.current,
          };
          socket.emit("focus_input", active_input);
          if (!filterContractActiveUser?.length) {
            activeUsersRef.current = [
              ...activeUsersRef.current,
              { active_input, ...(user.employee || {}) },
            ];
          }
        }
      },
      500 // Debounce delay in milliseconds
    ),
    [user]
  );

  useEffect(() => {
    addSocketIdInterceptor(socket);

    // Cleanup the interceptor when component unmounts
    return () => {
      instance.interceptors.request.eject();
    };
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    if (user) {
      // Fetch the notification count

      if (!initialFetched.current) {
        summaryRef.current = [];
        initialFetched.current = true;
        fetchClient();
        fetchParentOptions();
        getAllEmployees();
        getSalesEmployees();
        fetchRegion();
        fetchClientType();
        fetchSchool();
        fetchEventType();
        fetchEmployees();
        onGetProductionPricing();
        onGetTransPricing();
        fetchVenue();
        onGetESPricing();
        onGetTransType();
        dispatch(getEventOptionsThunk());
        document.addEventListener("mousemove", handlePointMove);
      }
    }

    return () => {
      document.removeEventListener("mousemove", handlePointMove);
    };
    // eslint-disable-next-line
  }, [user]);

  const fetchRegion = () => {
    getRegions().then((regions) => {
      setGetRegion(regions.data);
    });
  };

  const fetchClientType = () => {
    getClientTypes().then((getClientType) =>
      setGetClientType(getClientType.data)
    );
  };

  const fetchSchool = () => {
    getSchools().then((getSchoolName) => setGetSchoolName(getSchoolName.data));
  };

  const fetchEventType = () => {
    getEventTypes().then((getEventType) => setGetEventType(getEventType.data));
  };

  const fetchEmployees = () => {
    fetch("/v1/employees")
      .then((response) => response.json())
      .then((getEmployees) => setEmployees(getEmployees));
  };

  const onGetProductionPricing = async () => {
    try {
      getProductionDetails().then((res) => setProductionPriceList(res.data));
    } catch (err) {
      console.error(err.message);
    }
  };

  const onGetTransPricing = async () => {
    try {
      getTransportationDetails().then((res) => setTransPriceList(res.data));
    } catch (err) {
      console.error(err.message);
    }
  };

  const onGetTransType = async () => {
    try {
      getTransportationType().then((res) => setTransType(res.data));
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchVenue = () => {
    getVenueData().then((getVenue) => setGetVenue(getVenue.data));
  };

  const handleClearStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("region");
    localStorage.removeItem("data ");
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    localStorage.removeItem("totalCapacity");
    localStorage.removeItem("event_staff_tc");
    localStorage.removeItem("transportation_tc");
    localStorage.removeItem("production_tc");
  };

  // Create NEW Client
  const updateClientName = async (name, clientType) => {
    const clientPromise = createClient({
      name,
      clientType,
    });
    return clientPromise
      .then((res) => {
        generateToast("Client Created!", SUCCESS);
        fetchClient();
        return res.data;
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
  // Create NEW Client

  // Create NEW Region
  const updateRegion = async (name) => {
    const regionPromise = createRegion({ name });

    return regionPromise
      .then((res) => {
        generateToast("Region Created!", SUCCESS);
        fetchRegion();
        return res.data;
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  // Create NEW Event Type
  const updateEventType = async (name) => {
    const eventTypePromise = createEventType({
      name,
    });

    return eventTypePromise
      .then((res) => {
        if (res.data.isApproved === false) {
          generateToast(
            "Event Type submitted for approval. You'll receive a notification once it's accepted or rejected.",
            SUCCESS
          );
        } else {
          generateToast("Event Type Created!", SUCCESS);
        }

        fetchEventType(); // Refresh the list of event types
        return res.data;
      })
      .catch((err) => {
        console.error(err.message);
        generateToast(
          "Failed to create event type. Please try again later.",
          ERROR
        );
      });
  };

  // Create NEW School
  const updateSchool = async (name) => {
    const schoolPromise = createSchool({
      name,
    });

    return schoolPromise
      .then((res) => {
        console.log(
          { res },
          res.data.isApproved,
          res.data.isApproved === false
        );

        if (res.data.isApproved === false) {
          generateToast(
            "School submitted for approval. You'll receive a notification once it's accepted or rejected.",
            SUCCESS
          );
        } else {
          generateToast("School Created!", SUCCESS);
        }

        fetchSchool(); // Refresh the list of schools
        return res.data;
      })
      .catch((err) => {
        console.error(err.message);
        generateToast(
          "Failed to create school. Please try again later.",
          ERROR
        );
      });
  };

  // Create NEW Client Type
  const updateClientType = async (name) => {
    const clientTypePromise = createClientType({
      name,
    });

    return clientTypePromise
      .then((res) => {
        
        if (res.data.isApproved === false) {
          generateToast(
            "Client Type submitted for approval. You'll receive a notification once it's accepted or rejected.",
            SUCCESS
          );
        } else {
          generateToast("Client Type Created!", SUCCESS);
        }

        fetchClientType(); // Refresh the list of client types
        return res.data;
      })
      .catch((err) => {
        console.error(err.message);
        generateToast(
          "Failed to create client type. Please try again later.",
          ERROR
        );
      });
  };

  return (
    <AppContext.Provider
      value={{
        parentTitleWithOptions,
        activeContracts,
        setIsTemplateLoading,
        parentOptions,
        activeUsers,
        allEmployees,
        closeFun,
        templateText,
        isTemplateLoading,
        contracts,
        contractTab,
        contractTotal,
        editIndex,
        editorState,
        fetchClient,
        fetchClientType,
        fetchDetails,
        fetchEventType,
        fetchPricing: onGetTransPricing,
        fetchRegion,
        fetchSchool,
        fetchVenue,
        formTabIndex,
        freezeHandle,
        getAllEmployees,
        getClientName,
        getClientType,
        getEmployees,
        getESPriceList,
        getEventType,
        getNotifications,
        getProductionPriceList,
        getRegion,
        getSchoolName,
        getSummary,
        getTransPriceList,
        getTransQuant,
        getUpFrontCost,
        getVenue,
        handleClearStorage,
        handlePointMove,
        handleReset,
        initialFetched,
        initializeEditor,
        isApiLoading,
        isAuthenticated,
        isLoading,
        isTypeLoading,
        mapIndex,
        mapIndexRef,
        notifications,
        onGetESPricing,
        onGetProductionPricing,
        onGetTransPricing,
        salesEmp,
        salesEmpWithOptions,
        setContracts,
        setContractTabs,
        setEditorDB,
        setEditorState,
        setIsAuthenticated,
        setLoading,
        setMapIndex,
        setSetIndex,
        setSummary: handleSummary,
        setSummaryArray: setSummary,
        setTabRef,
        fetchParentOptions,
        fetchContractTemplateToState,
        setTransportationPriceList,
        setTypeLoading,
        setUser,
        socket,
        summary,
        summaryRef,
        tabIndex,
        totalESCost,
        totalPCost,
        totalTransCost,
        transportationPriceList,
        updateClientName,
        updateContract,
        updatePDFContract,
        updateRegion,
        updateEventType,
        updateSchool,
        updateClientType,
        onGetTransType,
        role,
        getTransType,
        user,
        isStaffer,
        isAdmin,
        totalAccountCost,
        templateVersion,
        handleContractUpdate,
        fetchBarSalesClients,
        barSalesClients,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
