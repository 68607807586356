import React, { useContext, useEffect } from "react";
import AAvatar from "../../atoms/MUI/Avatar";
import { AppContext } from "../../provider/AppContext";
import {
  NotificationButton,
  NotificationButtonWrapper,
  NotificationWrapper,
} from "../../styled";
import { useTheme } from "../../ThemeContext";
import moment from "moment";
import Notification from "@mui/icons-material/Notifications";
import GroupIcon from "@mui/icons-material/Group";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationUnreadCount,
  getNotificationsContent,
  handleNotificationAction,
  handleNotificationRead,
} from "../../redux/reducers/NotificationReducer";
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

const NotificationComponent = ({ showNotifications, setNotifications }) => {
  const navigate = useNavigate();
  const themeToggle = useTheme();
  const { fetchClientType, fetchEventType } = useContext(AppContext);
  const { notifications } = useSelector((state) => state.Notification);
  const { backgroundThemeColor, textThemeColor, themeCardBackground } =
    themeToggle.themeState;
  const dispatch = useDispatch();
  const { user } = useContext(AppContext);

  const refreshNotifications = () => {
    dispatch(getNotificationUnreadCount({ isSeen: false }));
    dispatch(getNotificationsContent({ isRead: false }));
  };

  useEffect(() => {
    user && refreshNotifications();
    // eslint-disable-next-line
  }, [user]);

  const getContent = ({ createdBy, eventId, data, eventComment }) => {
    let content;

    const handleAction = async (type, eventId, accept) => {
      dispatch(
        handleNotificationAction({
          type,
          eventId,
          accept,
        })
      )
        .then(() => {
          refreshNotifications();
          fetchClientType();
          fetchEventType();
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const handleTemplateCheck = (templateId) => {
      setNotifications(false);
      navigate(`/template-contract/${templateId}`);
    };

    const handleAccept = async () => {
      handleAction(eventId, data.id, true);
    };

    const handleReject = () => {
      handleAction(eventId, data.id, false);
    };

    switch (eventId) {
      case 1:
        content = (
          <>
            <div>
              <strong className="tw-font-bold">{createdBy?.firstName}</strong>{" "}
              added client type{" "}
              <strong className="tw-font-bold">{data?.label}</strong> which
              needs your approval
            </div>
            <NotificationButtonWrapper>
              <NotificationButton onClick={handleAccept} width="120px">
                Approve
              </NotificationButton>
              <NotificationButton onClick={handleReject} width="120px">
                Reject
              </NotificationButton>
            </NotificationButtonWrapper>
          </>
        );
        break;
      case 2:
        content = (
          <>
            <div>
              <strong className="tw-font-bold">{createdBy?.firstName}</strong>{" "}
              added event type{" "}
              <strong className="tw-font-bold">{data?.label}</strong> which
              needs your approval
            </div>
            <NotificationButtonWrapper>
              <NotificationButton onClick={handleAccept} width="120px">
                Approve
              </NotificationButton>
              <NotificationButton onClick={handleReject} width="120px">
                Reject
              </NotificationButton>
            </NotificationButtonWrapper>
          </>
        );
        break;
      case 3:
        content = (
          <>
            Your created client type has been{" "}
            {eventComment ? "Approved" : "Rejected"}
          </>
        );
        break;
      case 4:
        content = (
          <>
            Your created event type has been{" "}
            {eventComment ? "Approved" : "Rejected"}
          </>
        );
        break;
      case 10:
        content = (
          <>
            <div>
              {" "}
              Contract template is changed by{" "}
              <strong>{createdBy?.firstName}</strong> needs your approval
            </div>
            <NotificationButtonWrapper>
              <NotificationButton
                onClick={() => handleTemplateCheck(eventComment)}
                width="120px"
              >
                Check
              </NotificationButton>
            </NotificationButtonWrapper>
          </>
        );
        break;
      case 11:
        content = <div>Your contract template changes has been Approved</div>;
        break;
      case 13:
        content = (
          <>
            <div>
              <strong className="tw-font-bold">{createdBy?.firstName}</strong>{" "}
              added school{" "}
              <strong className="tw-font-bold">{data?.label}</strong> which
              needs your approval
            </div>
            <NotificationButtonWrapper>
              <NotificationButton onClick={handleAccept} width="120px">
                Approve
              </NotificationButton>
              <NotificationButton onClick={handleReject} width="120px">
                Reject
              </NotificationButton>
            </NotificationButtonWrapper>
          </>
        );
        break;
      case 14:
        content = <>Your created school has been approved.</>;
        break;
      case 15:
        content = <>Your created school type has been rejected.</>;
        break;

      default:
        break;
    }
    return content;
  };
  return (
    <NotificationWrapper
      textThemeColor={textThemeColor}
      backgroundThemeColor={backgroundThemeColor}
      themeCardBackground={themeCardBackground}
      customBurgerIcon={false}
      isOpen={showNotifications}
      onClose={() => {
        setNotifications(false);
      }}
      right
      width={"600px"}
    >
      <h2>Notifications</h2>

      <div className="notification-content">
        {notifications
          ?.slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map(({ createdBy, createdAt, eventId, data, eventComment }, i) => {
            return (
              <div key={i} className="notification-card">
                <div>
                  <AAvatar userProfile={createdBy} />
                </div>
                <div className="tw-flex-grow">
                  <div className="tw-flex tw-justify-between tw-w-full">
                    <span className="user-text tw-mr-1.5">{`${createdBy?.firstName} ${createdBy?.lastName}`}</span>
                    <span className="time">
                      {moment(createdAt).format("MMM Do YY, h:mmA")}
                    </span>
                  </div>
                  <span>
                    {getContent({
                      createdBy,
                      eventId,
                      data,
                      eventComment,
                    })}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </NotificationWrapper>
  );
};

export const NotificationBadge = ({ getNotifications, setNotifications }) => {
  const { count } = useSelector((state) => state.Notification);
  const dispatch = useDispatch();

  return (
    <div
      onClick={() => {
        setNotifications(true);
        dispatch(handleNotificationRead()).then(
          dispatch(getNotificationUnreadCount({ isSeen: false }))
        );
      }}
      className="notification-wrapper"
    >
      <Badge badgeContent={count || 0} color="primary" className="tw-font-xs">
        <Notification className="tw-text-2xl" color="white" />
      </Badge>
    </div>
  );
};

export const UserBadge = ({ activeUsers, setActiveModal }) => {
  const userCount = useMemo(() => {
    const usersArray = Array.isArray(activeUsers?.[1])
      ? activeUsers[1]
      : Array.isArray(activeUsers?.[0])
      ? activeUsers[0]
      : [];

    return usersArray.filter((user) => !user.logoutTime).length;
  }, [activeUsers]);

  return (
    <div
      onClick={() => {
        setActiveModal(true);
      }}
      className="notification-wrapper active"
    >
      <Badge badgeContent={userCount} color="primary">
        <GroupIcon className="tw-text-2xl" color="white" />
      </Badge>
    </div>
  );
};

export default NotificationComponent;
