import { convertToRaw } from "draft-js";
import { round, toNumber } from "lodash";
import { toast } from "react-toastify";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import { BOTTOM_RIGHT } from "../constant";
import moment from "moment";

export const handleSorting = async (
  sortField,
  sortOrder,
  setTableData,
  contracts,
  setLoading
) => {
  if (sortField) {
    const sorted = [...contracts].sort((a, b) => {
      if (sortField === "firstname") {
        if (a?.creator?.[sortField] === null) return 1;
        if (b?.creator?.[sortField] === null) return -1;
        if (
          a?.creator?.[sortField] === null &&
          b?.creator?.[sortField] === null
        )
          return 0;
        return (
          a?.creator?.[sortField]
            .toString()
            .localeCompare(b?.creator?.[sortField].toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
        );
      } else if (sortField === "sold_by") {
        if (a?.sold_by?.firstname === null) return 1;
        if (b?.sold_by?.firstname === null) return -1;
        if (a?.sold_by?.firstname === null && b?.sold_by?.firstname === null)
          return 0;
        return (
          a?.sold_by?.firstname
            .toString()
            .localeCompare(b?.sold_by?.firstname.toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
        );
      } else if (sortField === "profit") {
        const AProfit = a.clientBudget - a.total_cost;
        const BProfit = b.clientBudget - b.total_cost;
        if (AProfit === null) return 1;
        if (BProfit === null) return -1;
        if (AProfit === null && BProfit === null) return 0;
        return (
          AProfit.toString().localeCompare(BProfit.toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      } else if (sortField === "profit_margin") {
        const AProfit =
          ((a?.clientBudget - a?.total_cost) / a?.clientBudget) * 100;
        const BProfit =
          ((b?.clientBudget - b?.total_cost) / b?.clientBudget) * 100;

        if (AProfit === Infinity) return 1;
        else if (sortOrder === "asc")
          return (
            convertStringToNumber(AProfit || 0, 10) -
            convertStringToNumber(BProfit || 0, 10)
          );
        else
          return (
            convertStringToNumber(BProfit || 0, 10) -
            convertStringToNumber(AProfit || 0, 10)
          );
      } else if (sortField === "contract_status") {
        const StatusA = a[sortField] || "In Progress";
        const StatusB = b[sortField] || "In Progress";

        return (
          StatusA.toString().localeCompare(StatusB.toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      } else {
        if (!a[sortField]) return 1;
        if (!b[sortField]) return -1;
        if (!a[sortField] && !b[sortField]) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      }
    });
    setTableData(sorted);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
};

export const convertStringToNumber = (number, decimal = 2) => {
  if (!number) return number;
  return round(toNumber(number), decimal);
};

export const validateDecimal = function (e) {
  var t = e.value;
  e.value =
    t.indexOf(".") >= 0
      ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
      : t;
};

export const getStatusColor = (status) => {
  if (status === "Canceled") {
    return 11; // red
  } else if (status === "Sent") {
    return 5; // yellow
  } else if (status === "Signed/Unpaid") {
    return 4; // salmon
  } else if (status === "In Progress") {
    return 8; // grey
  } else if (status === "Signed/Paid") {
    return 10; // green
  } else if (status === "Reconciled") {
    return 7; // teal
  } else if (status === "Postponed") {
    return 11; // red
  }
};

// Generate Toast
export const generateToast = (message, event, position = BOTTOM_RIGHT) => {
  const positionParam = {
    position,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  switch (event) {
    case "Success":
      toast.success(message, positionParam);
      break;
    case "Error":
      toast.error(message, positionParam);
      break;

    default:
      break;
  }
};

export const convertToPdf = (editorState) => {
  // Convert the editor state to a raw content state object
  const rawContent = convertToRaw(editorState.getCurrentContent());

  // Convert the raw content state object to a format that can be rendered by react-pdf
  const pdfContent = {
    blocks: rawContent.blocks.map((block) => ({
      key: block.key,
      text: block.text,
      type: block.type,
    })),
  };

  return pdfContent;
};

const lastYearDate = new Date();
lastYearDate.setFullYear(lastYearDate.getFullYear() - 1);

// {
//   label: "Year to Date (2nd Half)",
//   value: [new Date(new Date().getFullYear(), 6, 1), new Date()],
//   placement: "left",
// },
// {
//   label: "Last Year to Date (2nd Half)",
//   value: [new Date(new Date().getFullYear() - 1, 6, 1), lastYearDate],
//   placement: "left",
// },
export const predefinedBottomRanges = [
  {
    label: "Year to Date",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },

  {
    label: "Last Year to Date",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    ],
    placement: "left",
  },
  {
    label: "1st Half This Year",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 5, 30),
    ],
    placement: "left",
  },
  {
    label: "2nd Half This Year",
    value: [
      new Date(new Date().getFullYear(), 6, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
    placement: "left",
  },
  {
    label: "1st Half Last Year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear() - 1, 5, 30),
    ],
    placement: "left",
  },
  {
    label: "2nd Half Last Year",
    value: [
      new Date(new Date().getFullYear() - 1, 6, 1),
      new Date(new Date().getFullYear() - 1, 11, 31),
    ],
    placement: "left",
  },
  {
    label: "This Year",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
    placement: "left",
  },
  {
    label: "Last Year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear() - 1, 11, 31),
    ],
    placement: "left",
  },
  {
    label: "Next Year",
    value: [
      new Date(new Date().getFullYear() + 1, 0, 1),
      new Date(new Date().getFullYear() + 1, 11, 31),
    ],
    placement: "left",
  },
  // {
  //   label: "All time",
  //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
  //   placement: "left",
  // },
  {
    label: "All time",
    value: [
      new Date(2022, 0, 1),
      new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth(),
        new Date().getDate()
      ),
    ],
    placement: "left",
  },
  // created check to flip orientation of ranges if current date is before july 1st to make it omni directional or else stats returns zero
  {
    label: "School Year to Date",
    value: (function () {
      const currentDate = new Date();
      const schoolYearStart = new Date(currentDate.getFullYear(), 6, 1); // July 1st of the current year
      if (currentDate < schoolYearStart) {
        // If today's date is before July 1st, use the previous year's school year start
        schoolYearStart.setFullYear(currentDate.getFullYear() - 1);
      }
      return [schoolYearStart, currentDate];
    })(),
    placement: "left",
  },
  {
    label: "Last School Year to Date",
    value: (function () {
      const currentDate = new Date();
      // July 1st of the previous year
      const lastSchoolYearStart = new Date(currentDate.getFullYear() - 2, 6, 1);
      // Today's date in the previous year
      const lastYearEndDate = new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth(),
        currentDate.getDate()
      );

      // Ensure the start date is always before the end date
      return [lastSchoolYearStart, lastYearEndDate];
    })(),
    placement: "left",
  },

  {
    label: "School Year 2021-2022",
    value: [new Date(2021, 6, 1), new Date(2022, 5, 30)],
    placement: "left",
  },
  {
    label: "School Year 2022-2023",
    value: [new Date(2022, 6, 1), new Date(2023, 5, 30)],
    placement: "left",
  },
  {
    label: "School Year 2023-2024",
    value: [new Date(2023, 6, 1), new Date(2024, 5, 30)],
    placement: "left",
  },
  {
    label: "School Year 2024-2025",
    value: [new Date(2024, 6, 1), new Date(2025, 5, 30)],
    placement: "left",
  },
  {
    label: "Last Week",
    closeOverlay: false,
    value: (value) => {
      const [date = new Date()] = value || [];
      return [subDays(date, 7), subDays(date, 0)];
    },
    appearance: "default",
  },
  {
    label: "This Week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 0),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 0),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next Week",
    closeOverlay: false,
    value: (value) => {
      const [date = new Date()] = value || [];
      return [addDays(date, 7), addDays(date, 0)];
    },
    appearance: "default",
  },
];

export function calculateDifference(cost1, cost2) {
  const diff = (cost1 - cost2).toFixed(2);
  // eslint-disable-next-line
  if (diff == 0) {
    return null;
  }
  const percentDiff = cost2 === 0 ? "N/A" : ((diff / cost2) * 100).toFixed(1);
  const isPositive = diff > 0;
  const sign = isPositive ? "+" : "-";
  return {
    diff: `${sign}${Math.abs(diff).toLocaleString()}`,
    percentDiff: cost2 === 0 ? "N/A" : `${sign}${Math.abs(percentDiff)}%`,
    isPositive,
  };
}

export function calculatePercentageDiff(percent1, percent2) {
  percent1 = parseFloat(percent1);
  percent2 = parseFloat(percent2);
  if (percent2 === 0) {
    return null;
  }
  const diff = (percent1 - percent2).toFixed(2);
  // eslint-disable-next-line
  if (diff == 0) {
    return null;
  }
  const isPositive = diff > 0;
  const sign = isPositive ? "+" : "-";
  const percentDiff = ((diff / percent2) * 100).toFixed(1);
  return {
    percentDiff: `${sign}${Math.abs(percentDiff)}%`,
    difference: `${sign}${Math.abs(diff)}%`,
    isPositive,
  };
}

export function convertToNumber(strOrNum, nullValue = null) {
  if (strOrNum === null || strOrNum === undefined) {
    return nullValue;
  }

  const convertStr = strOrNum.toString().replace(/[^0-9.]/g, "");

  // Check if the string is empty or only contains a decimal point
  if (!convertStr || convertStr === ".") {
    return nullValue;
  }

  // If the string ends with a decimal point, return it directly
  if (convertStr.endsWith(".")) {
    return convertStr;
  }

  // Convert the string to a number
  const number = parseFloat(convertStr.replace(/,/g, ""));
  return number === 0 ? nullValue : number;
}

export const amountFormatter = (value) => {
  if (!value || value === "0") return "";
  const convertStr = value.toString();
  const onlyNumericValue = convertStr.replace(/[^0-9.]/g, ""); // Remove all non-numeric characters except the decimal point

  if (onlyNumericValue.endsWith(".")) {
    // If the string ends with a decimal point, format it without removing the point
    const withoutDecimal = onlyNumericValue.slice(0, -1);
    const formattedWithoutDecimal =
      parseFloat(withoutDecimal).toLocaleString("en-US");
    return formattedWithoutDecimal + ".";
  } else {
    // Otherwise, format the number with the necessary decimal places
    const parsedValue = parseFloat(onlyNumericValue);
    const formatOptions = {
      minimumFractionDigits: 0,
      maximumFractionDigits: parsedValue % 1 === 0 ? 0 : 2,
    };
    return parsedValue.toLocaleString("en-US", formatOptions);
  }
};

export function extractIds(obj, { addClientId } = {}) {
  const result = {};
  if ("region" in obj) {
    result.region = obj.region?.id || null;
  }

  if ("versionId" in obj) {
    result.versionId = obj.versionId || "";
  }
  
  if ("clientType" in obj) {
    result.clientType = obj.clientType?.id || null;
  }

  if ("title" in obj) {
    result.title = obj.title || "";
  }

  if ("eventName" in obj) {
    result.eventName = obj.eventName || "";
  }

  if ("parentEvent" in obj) {
    result.parentEvent = obj?.parentEvent?.id || null;
  }

  if ("shouldResetBillings" in obj) {
    result.shouldResetBillings = obj?.shouldResetBillings || false;
  }

  if ("eventClients" in obj) {
    result.eventClients = obj.eventClients.map((client) => {
      const clientObj = {
        client: client?.client?.id || null,
        ...(addClientId && { id: client.id }),
      };
      if ("collaborator" in client) {
        clientObj.collaborator = client.collaborator?.id || null;
      }

      if ("isSigner" in client) {
        clientObj.isSigner = client.isSigner || false;
      }

      if ("isSigner" in client) {
        clientObj.isSigner = client.isSigner || false;
      }

      if ("shouldShowFinancial" in client) {
        clientObj.shouldShowFinancial = client.shouldShowFinancial || false;
      }
      return clientObj;
    });
  }

  if ("soldBy" in obj) {
    result.soldBy = obj.soldBy?.id || null;
  }

  if ("startDate" in obj) {
    result.startDate = obj?.startDate || "";
  }

  if ("signDate" in obj) {
    result.signDate = obj.signDate || "";
  }

  if ("clientBudget" in obj) {
    result.clientBudget = obj.clientBudget || null;
  }

  if ("totalCost" in obj) {
    result.totalCost = obj.totalCost || 0;
  }

  if ("contractStatus" in obj) {
    result.contractStatus =
      obj.contractStatus?.label || obj.contractStatus || "";
  }

  if ("isPhantom" in obj) {
    result.isPhantom = obj.isPhantom || false;
  }

  if ("guestCount" in obj) {
    result.guestCount = obj.guestCount || "";
  }

  if ("eventType" in obj) {
    result.eventType = obj.eventType?.id || null;
  }

  if ("startTime" in obj) {
    result.startTime = obj.startTime || "";
  }

  if ("endTime" in obj) {
    result.endTime = obj.endTime || "";
  }

  if ("isClientBooked" in obj) {
    result.isClientBooked = obj.isClientBooked || false;
  }

  if ("isTPEBooked" in obj) {
    result.isTPEBooked = obj.isTPEBooked || false;
  }

  if ("tpeResponsibility" in obj) {
    result.tpeResponsibility = obj.tpeResponsibility || 0;
  }

  if ("tpeGamble" in obj) {
    result.tpeGamble = obj.tpeGamble || null;
  }

  if ("whatsTheDeal" in obj) {
    result.whatsTheDeal = obj.whatsTheDeal || "";
  }

  if ("venue" in obj) {
    result.venue = obj.venue || "";
  }

  if ("pdfContract" in obj) {
    result.pdfContract = obj.pdfContract || "";
  }

  if ("isInvoiced" in obj) {
    result.isInvoiced = obj.isInvoiced || false;
  }

  if ("barGuarantee" in obj) {
    result.barGuarantee = obj.barGuarantee || null;
  }

  if ("finalBarSalesDescription" in obj) {
    result.finalBarSalesDescription = obj.finalBarSalesDescription || "";
  }

  if ("pickUpTime" in obj) {
    result.pickUpTime = obj.pickUpTime || "";
  }

  if ("dropOffTime" in obj) {
    result.dropOffTime = obj.dropOffTime || "";
  }

  if ("guestCount" in obj) {
    result.guestCount = obj.guestCount || "";
  }

  if ("eventType" in obj) {
    result.eventType = obj.eventType?.id || "";
  }

  if ("startTime" in obj) {
    result.startTime = obj.startTime || null;
  }

  if ("endTime" in obj) {
    result.endTime = obj.endTime || null;
  }

  if ("isClientBooked" in obj) {
    result.isClientBooked = obj.isClientBooked || false;
  }

  if ("isTPEBooked" in obj) {
    result.isTPEBooked = obj.isTPEBooked || false;
  }

  if ("tpeResponsibility" in obj) {
    result.tpeResponsibility = obj.tpeResponsibility || 0;
  }

  if ("shouldConsiderClientResponsibility" in obj) {
    result.shouldConsiderClientResponsibility =
      obj.shouldConsiderClientResponsibility || false;
  }

  if ("isClientResponsibilityInvoiced" in obj) {
    result.isClientResponsibilityInvoiced =
      obj.isClientResponsibilityInvoiced || false;
  }

  if ("tpeGamble" in obj) {
    result.tpeGamble = obj.tpeGamble || null;
  }

  if ("whatsTheDeal" in obj) {
    result.whatsTheDeal = obj.whatsTheDeal || "";
  }

  if ("venue" in obj) {
    result.venue = obj.venue || "";
  }

  if ("pdfContract" in obj) {
    result.pdfContract = obj.pdfContract || "";
  }

  return result;
}

export function extractPhantomData(obj) {
  const result = {};

  if (obj.region) {
    result.region = obj.region;
  }

  if (obj.clientType) {
    result.clientType = obj.clientType;
  }

  if (obj.eventClients && obj.eventClients.length > 0) {
    result.eventClients = obj.eventClients;
  }

  if (obj.soldBy) {
    result.soldBy = obj.soldBy;
  }

  if (obj.startDate) {
    result.startDate = obj.startDate;
  }

  if (obj.signDate) {
    result.signDate = obj.signDate;
  }

  if (obj.clientBudget) {
    result.clientBudget = obj.clientBudget;
  }

  if (obj.totalCost) {
    result.totalCost = obj.totalCost || 0;
  }

  if (obj.contractStatus) {
    result.contractStatus = obj.contractStatus;
  }

  if (obj.isPhantom) {
    result.isPhantom = obj.isPhantom;
  }

  if (obj.id) {
    result.id = obj.id;
  }

  return result;
}

export const getKeyValue = (key, index = 0, summary) => {
  let value;
  switch (key) {
    case "School Name":
      value = summary?.eventClients?.[index]?.collaborator?.name || "";
      break;
    case "Client Name":
      value = summary?.eventClients?.[index]?.client?.name || "";
      break;
    case "Signer":
      value = summary?.eventClients?.[index]?.isSigner || false;
      break;
    case "shouldShowFinancial":
      value = summary?.eventClients?.[index]?.shouldShowFinancial || false;
      break;
    default:
      value = "";
      break;
  }

  return value;
};

export const calculateActiveStatus = (loginTime, logoutTime) => {
  const currentTime = moment();

  // Check if there's no logout time (user is currently active)
  if (!logoutTime) {
    const activeDuration = moment.duration(currentTime.diff(loginTime));
    return `Active for ${activeDuration.humanize()}`;
  }

  const lastLogoutTime = moment(logoutTime);
  const durationInSeconds = currentTime.diff(lastLogoutTime, "seconds");

  // Check if the user was active in the last minute
  if (durationInSeconds < 60) {
    return "Active now";
  }

  return `Last active ${moment
    .duration(durationInSeconds, "seconds")
    .humanize()} ago`;
};
